<template>
  <BackofficeBase :loader_prop="loader_prop">
    Delete Order
  </BackofficeBase>
</template>

<script>
import BackofficeBase from "./BackofficeBase";
import BackendModel from "../../models/BackendModel";

export default {
  name: 'BackofficePaymeOrderDelete',
  components: {
    BackofficeBase
  },
  data() {
    return {
      loader_prop: true
    }
  },
  async mounted() {
    let backendModel = new BackendModel()
    await backendModel.backendRequest("/Api/service/backoffice/backoffice_payme_order_delete", {order_id : this.$route.query.id})
    await this.$router.push({ path: '/backoffice/payme' })
  }
}
</script>

<style>
.item_action {
  font-size: 10px;
}
</style>
